import {TelegramWebApps} from "../../global";

class App {
    constructor(Telegram: TelegramWebApps.SDK) {
        const body = document.querySelector<HTMLElement>('body')
        const heading = document.querySelector<HTMLElement>('#page-1 > h1')

        if (!body || !heading) {
            return
        }

        this.verifyData(Telegram.WebApp.initData).then(res => {
            Telegram.WebApp.ready()
            heading.innerText = res.reason
            body.style.display = 'block'

            if (! res.valid) {
                Telegram.WebApp.HapticFeedback.notificationOccurred('error')
                return
            }

            Telegram.WebApp.MainButton.onClick(() => {
                window.location.href = '#page-2'
                Telegram.WebApp.BackButton.show()
            })

            Telegram.WebApp.BackButton.onClick(() => {
                window.location.href = '#page-1'
                Telegram.WebApp.BackButton.hide()
            })
        })
    }

    async verifyData(initData: string) {
        const result = {
            valid: true,
            reason: 'Watcha need?',
        }

        const resp = await fetch('https://4d_request.pierlo.workers.dev/verify', {
            method: 'POST',
            body: new URLSearchParams(initData)
        })

        if (!resp.ok) {
            result.valid = false

            switch (resp.status) {
                case 403:
                    result.reason = 'Umm... try again?'
                    break
                case 401:
                    result.reason = 'Never heard of ya'
                    break
                default:
                    result.reason = 'Something went wrong'
            }
        }

        return result
    }

    getActivePageNum() {
        let page = 1
        if (window.location.hash) {
            const match = window.location.hash.match(/page-(\d)/)
            if (match?.[1]) {
                page = Number(match[1])
            }
        }
        return page
    }
}

export default App
